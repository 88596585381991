<template>
    <CCard bodyWrapper>
        <p class="h4 m-0">{{ greeting }} {{ role }}, {{ name }}!</p>
    </CCard>
</template>

<script>
    import encryptStorage from "../../controllers/encryptStorage.js";
    import _capitalize from 'lodash/capitalize';

    export default {
        name: 'DashboardGreeting',
        data() {
            return {
                greeting: 'Good Day',
                name: encryptStorage.getItem("loggedInUserFirstName") + " " + encryptStorage.getItem("loggedInUserSurname"),
                role: (encryptStorage.getItem("loggedInUserRole") == 'admin') ? "Approver" : _capitalize(encryptStorage.getItem("loggedInUserRole"))
            };
        },
        mounted: function(){
            let myDate = new Date();
            let hrs = myDate.getHours();
            if (hrs < 12)
                this.greeting = 'Good Morning';
            else if (hrs >= 12 && hrs <= 17)
                this.greeting = 'Good Afternoon';
            else if (hrs >= 17 && hrs <= 24)
                this.greeting = 'Good Evening';
            
        }
    }
</script>