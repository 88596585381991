<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          Delegates
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="items"
            :fields="fields"
            :items-per-page="5"
            pagination
          >
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";

  export default {
    name: 'DashboardDelegates',
    computed: {
      ...mapState(["endPoint"])
    },
    data () {
      return {
        bookingStats: {},
        items: [],
        fields: [
          { key: 'name'},
          { key: 'course_title'},
          { key: 'booking_date'},
        ]
      }
    },
    mounted: async function() {
        var response = await HTTPRequest.getRequest(
            this.endPoint, 
            "/api/v1/delegates/dashboard_delegates",
            null, 
            null
        );
        this.items = response.delegates
    },
    methods: {
    }
  }
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
</style>