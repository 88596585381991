<template>
  <CRow>
    <CCol sm="12" lg="4" @click="clickCard('provisional_booking')" class="clickable">
      <CWidgetProgressIcon 
        
        v-if="bookingStats"
        :header="bookingStats.provisional ? bookingStats.provisional : '0'"
        text="Provisional Bookings"
        color="primary"
        :value=100
      >
        <CIcon name="cil-asterisk-circle" height="36"/>
      </CWidgetProgressIcon>
      <a-spin v-else class="d-flex justify-content-center mb-2"/>
    </CCol>
    <CCol sm="12" lg="4" @click="clickCard('for_approval')" class="clickable">
      <CWidgetProgressIcon
        v-if="bookingStats"
        :header="bookingStats.for_approval ? bookingStats.for_approval : '0'"
        text="For Approval Bookings"
        color="info"
        :value=100
      >
        <CIcon name="cil-magnifying-glass" height="36"/>
      </CWidgetProgressIcon>
      <a-spin v-else class="d-flex justify-content-center mb-2"/>
    </CCol>
    <CCol sm="12" lg="4" @click="clickCard('booked')" class="clickable">
      <CWidgetProgressIcon
        v-if="bookingStats"
        :header="bookingStats.booked ? bookingStats.booked : '0'"
        text="Approved Bookings"
        color="success"
        :value=100
      >
        <CIcon name="cil-check-circle" height="36"/>
      </CWidgetProgressIcon>
      <a-spin v-else class="d-flex justify-content-center mb-2"/>
    </CCol>
    <CCol sm="12" lg="4" @click="clickCard('draft')" class="clickable">
      <CWidgetProgressIcon
        v-if="bookingStats"
        :header="bookingStats.draft ? bookingStats.draft : '0'"
        text="Draft Bookings"
        color="dark"
        :value=100
      >
        <CIcon name="cil-circle" height="36"/>
      </CWidgetProgressIcon>
      <a-spin v-else class="d-flex justify-content-center mb-2"/>
    </CCol>
    <CCol sm="12" lg="4" @click="clickCard('rejected')" class="clickable">
      <CWidgetProgressIcon
        v-if="bookingStats"
        :header="bookingStats.rejected ? bookingStats.rejected : '0'"
        text="Rejected Bookings"
        color="warning"
        :value=100
      > 
        <CBadge class="mr-2" color="danger" v-if="bookingStats.rejected_notification_count > 0"> {{ bookingStats.rejected_notification_count }} </CBadge> 
        <CIcon name="cil-chevron-circle-down-alt" height="36"/>
      </CWidgetProgressIcon>
      <a-spin v-else class="d-flex justify-content-center mb-2"/>
    </CCol>
    <CCol sm="12" lg="4" @click="clickCard('cancelled')" class="clickable">
        <CWidgetProgressIcon
          v-if="bookingStats"
          :header="bookingStats.cancelled ? bookingStats.cancelled : '0'"
          text="Cancelled Bookings"
          color="danger"
          :value=100
        >
          
          <CIcon name="cil-x-circle" height="36"/>
        </CWidgetProgressIcon>
        <a-spin v-else class="d-flex justify-content-center mb-2"/>
    </CCol>

    <CCol sm="12" lg="12" @click="clickCard('expiring_provisional')" class="clickable">
        <CWidgetProgressIcon
          v-if="bookingStats"
          :header="bookingStats.expiring_provisional ? bookingStats.expiring_provisional : '0'"
          text="Expiring Provisional Bookings"
          color="danger"
          :value=100
        >

          <CIcon name="cil-x-circle" height="36"/>
        </CWidgetProgressIcon>
        <a-spin v-else class="d-flex justify-content-center mb-2"/>
    </CCol>
  </CRow>
</template>

<script>
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";

  export default {
    name: 'DashboardCards',
    computed: {
      ...mapState(["endPoint"])
    },
    data () {
      return {
        bookingStats: {},
      }
    },
    mounted: async function() {
      let booking_stats = await HTTPRequest.getRequest(this.endPoint, "/api/v1/bookings/get_dashboard_booking_stats", null, null);
      this.bookingStats = booking_stats
    },
    methods: {
      async clickCard(type) {
        await HTTPRequest.postRequest(
            this.endPoint, 
            "/api/v1/users/read_notification?code=rejected_notification_dashboard",
            null,
            null);
        this.$router.push({ name: 'Bookings', params: { type: type } })
      },
    }
  }
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
</style>