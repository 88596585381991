<template>
  <div>
    <CToaster :autohide="3000">
      <CToast
        :show="showSessionExistsToaster"
        color="info"
        header="Notification"
      >
        You still have an active session.
      </CToast>
    </CToaster>

    <DashboardGreeting />
    <DashboardCards />

    <div class="row">
      <div class="col">
        <DashboardDelegates />
      </div>
      <div class="col">
        <DashboardFeedbacks />
      </div>
    </div>
    
    
  </div>
</template>

<script>
  import HTTPRequest from "../controllers/httpRequest.js";
  import encryptStorage from "../controllers/encryptStorage.js";
  import DashboardGreeting from './widgets/DashboardGreeting.vue'
  import DashboardCards from './widgets/DashboardCards.vue'
  import DashboardDelegates from './widgets/DashboardDelegates.vue'
  import DashboardFeedbacks from './widgets/DashboardFeedbacks.vue'
  import { mapState } from "vuex";
  
  export default {
    name: 'Dashboard',
    computed: {
      ...mapState(["endPoint"])
    },
    components: {
      DashboardCards,
      DashboardGreeting,
      DashboardDelegates,
      DashboardFeedbacks
    },
    data () {
      return {
        showSessionExistsToaster: false,
        bookingStats: {},
      }
    },
    mounted: async function() {
      this.showSessionExistsToaster = encryptStorage.getItem("sessionExists") || false;
      encryptStorage.setItem("sessionExists", false);

      let booking_stats = await HTTPRequest.getRequest(this.endPoint, "/api/v1/bookings/get_dashboard_booking_stats", null, null);
      this.bookingStats = booking_stats
    },
    methods: {
    }
  }
</script>