<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          Feedbacks
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="items"
            :fields="fields"
            :items-per-page="5"
            pagination
          >
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";

  export default {
    name: 'DashboardFeedbacks',
    computed: {
      ...mapState(["endPoint"])
    },
    data () {
      return {
        bookingStats: {},
        items: [],
        fields: [
          { key: 'user_full_name'},
          { key: 'feedback_type', label: "Type"},
          { key: 'is_action', label: "Action"},
          { key: 'comment'},
          { key: 'created_at_time', label: "Time"},
          { key: 'course_title'},
        ]
      }
    },
    mounted: async function() {
        var response = await HTTPRequest.getRequest(
            this.endPoint, 
            "/api/v1/feedbacks/dashboard_feedbacks",
            null, 
            null
        );
        this.items = response.feedbacks
    },
    methods: {
    }
  }
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
</style>